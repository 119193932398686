<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
          <h4 class="card-title">{{ $t('warehouse_report.month-wise-product-received-report') }}</h4>
      </template>
      <template v-slot:body>
        <b-row>
          <slot v-if="authUser.warehouse_id == null">
              <b-col xs="12" sm="12" md="6" lg="4">
                  <b-form-group
                      class="row"
                      label-cols-sm="3"
                      label-cols-md="4"
                      :label="$t('org_pro_division.division')"
                      label-for="division_id"
                      >
                      <b-form-select
                      plain
                      v-model="search.division_id"
                      :options="divisionList"
                      id="division_id"
                      >
                      <template v-slot:first>
                          <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                      </b-form-select>
                  </b-form-group>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="4">
                  <b-form-group
                  class="row"
                  label-cols-sm="3"
                  label-cols-md="4"
                  :label="$t('warehouse_config.region')"
                  label-for="region_id"
                  >
                  <b-form-select
                  plain
                  v-model="search.region_id"
                  :options="regionList"
                  id="division_id"
                  >
                  <template v-slot:first>
                      <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                  </b-form-select>
                  </b-form-group>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="4">
                  <b-form-group
                  class="row"
                  label-cols-sm="3"
                  label-cols-md="4"
                  :label="$t('warehouse_report.district')"
                  label-for="district_id"
                  >
                  <b-form-select
                  plain
                  v-model="search.district_id"
                  :options="regionDistrictLists"
                  id="district_id"
                  >
                  <template v-slot:first>
                      <b-form-select-option :value="0">{{ districtLoading ? $t('globalTrans.loading') : $t('globalTrans.select') }}</b-form-select-option>
                  </template>
                  </b-form-select>
                  </b-form-group>
              </b-col>
                <b-col xs="12" sm="12" md="6" lg="4">
                    <b-form-group
                    class="row"
                    label-cols-sm="3"
                    label-cols-md="4"
                    :label="$t('warehouse_report.upazilla')"
                    label-for="upazilla_id"
                    >
                    <b-form-select
                    plain
                    v-model="search.upazila_id"
                    :options="upazilaList"
                    id="upazilla_id"
                    >
                    <template v-slot:first>
                        <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                    </template>
                    </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="4">
                    <b-form-group
                    class="row"
                    label-cols-sm="3"
                    label-cols-md="4"
                    :label="$t('globalTrans.union')"
                    label-for="union_id"
                    >
                    <b-form-select
                    plain
                    v-model="search.union_id"
                    :options="unionList"
                    id="union_id"
                    >
                    <template v-slot:first>
                        <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                    </template>
                    </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="4">
                    <b-form-group
                        class="row"
                        label-cols-sm="3"
                        label-cols-md="4"
                        label-for="commodity_group_id"
                        :label="$t('warehouse_config.warehouse_type')"
                        >
                        <b-form-select
                        plain
                        v-model="search.warehouse_type_id"
                        :options="warehouseTypeList"
                        id="commodity_group_id"
                        >
                        <template v-slot:first>
                        <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select>
                    </b-form-group>
                </b-col>
          </slot>
          <b-col xs="12" sm="12" md="6" lg="4">
            <b-form-group
                  class="row"
                  label-cols-sm="3"
                  label-cols-md="4"
                  label-for="warehouse_id"
                  :label="$t('warehouse_information.warehouse_name')"
              >
                <b-form-select
                    plain
                    v-model="search.warehouse_id"
                    :options="warehouseList"
                    id="warehouse_id"
                    v-if="!authUser.warehouse_id"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
                <b-form-select
                    plain
                    v-model="search.warehouse_id"
                    :options="warehouseList"
                    id="warehouse_id"
                    v-else
                >
                </b-form-select>
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="4">
              <b-form-group
              class="row"
              label-cols-sm="3"
              label-cols-md="4"
              label-for="commodity_group_id"
              :label="$t('warehouse_report.fiscal_year')"
              >
              <b-form-select
                  plain
                  v-model="search.fiscal_year_id"
                  :options="fiscalYearList"
                  id="commodity_group_id"
                  >
                  <template v-slot:first>
                  <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
              </b-form-select>
              </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="4">
              <b-button type="button" variant="primary float-right" @click="searchData">{{ $t('globalTrans.submit')}}</b-button>
          </b-col>
          <b-col>
          </b-col>
        </b-row>
      </template>
    </iq-card>
    <b-row v-show="showData">
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('warehouse_report.month-wise-product-received-report') }}</h4>
          </template>
          <template v-slot:headerAction>
                <b-button class="btn_add_new mr-2" @click="pdfExport">
                  <i class="far fa-file-pdf"></i>{{  $t('globalTrans.export_pdf') }}
                </b-button>
                <!-- <export-excel
                    class="btn btn-success mr-2"
                    :data="monthWiseReport"
                    title="Month Wise Product Recieve Report"
                    worksheet="Month Wise Product Recieve Report Sheet"
                    name="filename.xls">
                    {{ $t('globalTrans.export_excel') }}
                </export-excel> -->
                <export-excel
                  class="btn btn_add_new"
                  :data="dataCustomizeExcel"
                  :fields="json_fields"
                  :title="headerInfo"
                  worksheet="Month Wise Product Recieve Report Sheet"
                  name="month-wise-product-receive-report.xls">
                  <i class="far fa-file-excel"></i>{{ $t('globalTrans.export_excel') }}
                </export-excel>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
                <b-row>
                    <b-col>
                        <list-report-head :base-url="warehouseServiceBaseUrl" uri="master-warehouse-report-heading/detail" :org-id="search.org_id" :loading-state="loadingState">
                          {{ $t('warehouse_report.month-wise-product-received-report') }}
                        </list-report-head>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col></b-col>
                </b-row>
              <b-row>
                <b-col md="3">
                  <strong>{{ $t('globalTrans.division') }}: </strong> {{ getDivisionName(search.division_id) }}
                </b-col>
                <b-col md="3">
                  <strong>{{ $t('warehouse_config.region') }}: </strong> {{ getRegionName(search.region_id) }}
                </b-col>
                <b-col md="3">
                  <strong>{{ $t('globalTrans.district') }}: </strong> {{ getDistrictName(search.district_id) }}
                </b-col>
                <b-col md="3">
                  <strong>{{ $t('globalTrans.upazila') }}: </strong> {{ getUpazilaName(search.upazilla_id) }}
                </b-col>
              </b-row>
              <b-row>
                <b-col md="3">
                  <strong>{{ $t('globalTrans.union') }}: </strong> {{ getUnionName(search.union_id) }}
                </b-col>
                <b-col md="3">
                  <strong>{{ $t('warehouse_config.warehouse_type') }}: </strong> {{ getWarehouseTypeName(search.warehouse_type_id) }}
                </b-col>
                <b-col md="3">
                  <strong>{{ $t('warehouse_information.warehouse_name') }}: </strong> {{ getWarehouseNameCustom(search.warehouse_id) }}
                </b-col>
                <b-col md="3">
                  <strong>{{ $t('warehouse_config.fiscal_year') }}: </strong> {{ getFiscalYearName(search.fiscal_year_id) }}
                </b-col>
              </b-row>
              <b-row class="mt-5">
                <b-col md="12" class="table-responsive">
                  <div>
                      <b-table-simple hover small caption-top responsive aria-hidden="loading | listReload ? 'true' : null">
                        <b-thead>
                          <b-tr>
                            <b-th>{{ $t('globalTrans.sl_no') }}</b-th>
                            <b-th>{{ $t('warehouse_report.crop_type') }}</b-th>
                            <b-th>{{ $t('warehouse_report.crop_name') }}</b-th>
                            <b-th>{{ $t('months.jul') }}</b-th>
                            <b-th>{{ $t('months.aug') }}</b-th>
                            <b-th>{{ $t('months.sep') }}</b-th>
                            <b-th>{{ $t('months.oct') }}</b-th>
                            <b-th>{{ $t('months.nov') }}</b-th>
                            <b-th>{{ $t('months.dec') }}</b-th>
                            <b-th>{{ $t('months.jan') }}</b-th>
                            <b-th>{{ $t('months.feb') }}</b-th>
                            <b-th>{{ $t('months.mar') }}</b-th>
                            <b-th>{{ $t('months.apr') }}</b-th>
                            <b-th>{{ $t('months.may') }}</b-th>
                            <b-th>{{ $t('months.jun') }}</b-th>
                            <b-th>{{ $t('warehouse_report.total_farmer') }}</b-th>
                          </b-tr>
                        </b-thead>
                        <b-tbody  v-for="(info, index) in monthWiseReportList" :key="index">
                          <!-- <b-tr>
                              <b-td :rowspan="info.total">{{ index+1 }}</b-td>
                              <b-th :rowspan="info.total"><span class="capitalize">{{ info.crop_type_name }}</span></b-th>
                          </b-tr> -->
                          <template v-for="(infoDetail, indx) in info.data">
                                <b-tr :key="index + indx" v-if="indx">
                                    <!-- <b-td> </b-td>
                                    <b-td> </b-td> -->
                                  <b-td><span class="capitalize">{{ $i18n.locale === 'en' ? infoDetail.crop_name : infoDetail.crop_name_bn }}</span></b-td>
                                  <b-td>{{ $n(infoDetail.in_july) }}</b-td>
                                  <b-td>{{ $n(infoDetail.in_august) }}</b-td>
                                  <b-td>{{ $n(infoDetail.in_september) }}</b-td>
                                  <b-td>{{ $n(infoDetail.in_october) }}</b-td>
                                  <b-td>{{ $n(infoDetail.in_november) }}</b-td>
                                  <b-td>{{ $n(infoDetail.in_december) }}</b-td>
                                  <b-td>{{ $n(infoDetail.in_january) }}</b-td>
                                  <b-td>{{ $n(infoDetail.in_february) }}</b-td>
                                  <b-td>{{ $n(infoDetail.in_march) }}</b-td>
                                  <b-td>{{ $n(infoDetail.in_april) }}</b-td>
                                  <b-td>{{ $n(infoDetail.in_may) }}</b-td>
                                  <b-td>{{ $n(infoDetail.in_june) }}</b-td>
                                  <b-td>{{ $n(infoDetail.farmer_number) }}</b-td>
                                </b-tr>
                                <b-tr :key="index + indx" v-else class="dfs">
                                    <b-td :rowspan="info.total">{{ $n(index+1) }}</b-td>
                                    <b-th :rowspan="info.total"><span class="capitalize">{{ $i18n.locale === 'en' ? info.crop_type_name : info.crop_type_name_bn }}</span></b-th>
                                 <b-td><span class="capitalize">{{ $i18n.locale === 'en' ? infoDetail.crop_name : infoDetail.crop_name_bn }}</span></b-td>
                                  <b-td>{{ $n(infoDetail.in_july) }}</b-td>
                                  <b-td>{{ $n(infoDetail.in_august) }}</b-td>
                                  <b-td>{{ $n(infoDetail.in_september) }}</b-td>
                                  <b-td>{{ $n(infoDetail.in_october) }}</b-td>
                                  <b-td>{{ $n(infoDetail.in_november) }}</b-td>
                                  <b-td>{{ $n(infoDetail.in_december) }}</b-td>
                                  <b-td>{{ $n(infoDetail.in_january) }}</b-td>
                                  <b-td>{{ $n(infoDetail.in_february) }}</b-td>
                                  <b-td>{{ $n(infoDetail.in_march) }}</b-td>
                                  <b-td>{{ $n(infoDetail.in_april) }}</b-td>
                                  <b-td>{{ $n(infoDetail.in_may) }}</b-td>
                                  <b-td>{{ $n(infoDetail.in_june) }}</b-td>
                                  <b-td>{{ $n(infoDetail.farmer_number) }}</b-td>
                                </b-tr>
                            </template>
                        </b-tbody>
                        <b-tfoot>
                          <b-tr>
                            <b-td colspan="3" variant="secondary" class="text-left">
                              {{ $i18n.locale === 'en' ? 'Gross Total (Quintal):' : 'সর্ব মোট (কুইন্টাল):' }}
                            </b-td>
                            <b-td variant="secondary" class="text-left">{{ $n(inJuly) }}</b-td>
                            <b-td variant="secondary" class="text-left">{{ $n(inAugust) }}</b-td>
                            <b-td variant="secondary" class="text-left">{{ $n(inSeptember) }}</b-td>
                            <b-td variant="secondary" class="text-left">{{ $n(inOctober) }}</b-td>
                            <b-td variant="secondary" class="text-left">{{ $n(inNovember) }}</b-td>
                            <b-td variant="secondary" class="text-left">{{ $n(inDecember) }}</b-td>
                            <b-td variant="secondary" class="text-left">{{ $n(inJanuary) }}</b-td>
                            <b-td variant="secondary" class="text-left">{{ $n(inFebruary) }}</b-td>
                            <b-td variant="secondary" class="text-left">{{ $n(inMarch) }}</b-td>
                            <b-td variant="secondary" class="text-left">{{ $n(inApril) }}</b-td>
                            <b-td variant="secondary" class="text-left">{{ $n(inMay) }}</b-td>
                            <b-td variant="secondary" class="text-left">{{ $n(inJune) }}</b-td>
                            <b-td variant="secondary" class="text-left">{{ $n(totalFarmer) }}</b-td>
                          </b-tr>
                        </b-tfoot>
                      </b-table-simple>
                  </div>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import RestApi, { warehouseServiceBaseUrl } from '@/config/api_config'
import { monthWiseReportList } from '../../api/routes'
import ExportPdf from '@/Utils/export-pdf'
import ListReportHead from '@/components/custom/ListReportHead.vue'
import excel from 'vue-excel-export'
import Vue from 'vue'
Vue.use(excel)

const excelColumn = {
  SL: 'serial',
  'Crop Type Name': 'cropTypeName',
  'Crop Name': 'cropName',
  'July ': 'jul',
  'August ': 'aug',
  'September ': 'sep',
  'October ': 'oct',
  'November ': 'nov',
  'December ': 'dec',
  'January ': 'jan',
  'Feburary ': 'feb',
  'March ': 'mar',
  'April ': 'apr',
  'May ': 'may',
  'June ': 'jun',
  'Farmer ': 'farmer'
}

const excelColumnBn = {
  'ক্রমিক নং': 'serial',
  'ফসলের ধরনের নাম': 'cropTypeName',
  'ফসলের নাম': 'cropName',
  'জুলাই ': 'jul',
  'আগস্ট ': 'aug',
  'সেপ্টেম্বর ': 'sep',
  'অক্টোবর ': 'oct',
  'নভেম্বর ': 'nov',
  'ডিসেম্বর ': 'dec',
  'জানুয়ারী ': 'jan',
  'ফেব্রুয়ারী ': 'feb',
  'মার্চ ': 'mar',
  'এপ্রিল ': 'apr',
  'মে ': 'may',
  'জুন ': 'jun',
  'কৃষক ': 'farmer'
}

export default {
  components: {
      ListReportHead
  },
  data () {
    return {
      pagination: {
        currentPage: 1,
        totalRows: 0,
        slOffset: 1
      },
      showData: false,
      showHeading: false,
      division_id: 0,
      region_id: 0,
      totalFarmer: '',
      inJuly: '',
      inAugust: '',
      inSeptember: '',
      inOctober: '',
      inNovember: '',
      inDecember: '',
      inJanuary: '',
      inFebruary: '',
      inMarch: '',
      inApril: '',
      inMay: '',
      inJune: '',
    search: {
        org_id: 13,
        region_id: 0,
        division_id: 0,
        district_id: 0,
        upazila_id: 0,
        union_id: 0,
        warehouse_id: 0,
        warehouse_type_id: 0,
        fiscal_year_id: 0
      },
      testId: 0,
      monthWiseReportList: [],
      monthWiseReport: [],
      regionDistrictLists: [],
      upazilaList: [],
      unionList: [],
      warehouseList: [],
      tempList: [],
      rows: [],
      warehouseServiceBaseUrl: warehouseServiceBaseUrl,
      districtLoading: false
    }
  },
  computed: {
     json_fields: function () {
      return this.$i18n.locale === 'bn' ? excelColumnBn : excelColumn
    },
     dataCustomizeExcel () {
       const customizeData = []
       this.monthWiseReportList.forEach((item, index) => {
         const serial = index + 1
         let nestedItemCounter = 1
         item.data.forEach(nestedItem => {
           const item1 = {}
           if (this.$i18n.locale === 'bn') {
             item1.serial = nestedItemCounter === 1 ? this.$n(serial) : ''
             item1.cropTypeName = nestedItemCounter === 1 ? item.crop_type_name_bn : ''
             item1.cropName = nestedItem.crop_name
             item1.jul = this.$n(nestedItem.in_july)
             item1.aug = this.$n(nestedItem.in_august)
             item1.sep = this.$n(nestedItem.in_september)
             item1.oct = this.$n(nestedItem.in_october)
             item1.nov = this.$n(nestedItem.in_november)
             item1.dec = this.$n(nestedItem.in_december)
             item1.jan = this.$n(nestedItem.in_january)
             item1.feb = this.$n(nestedItem.in_february)
             item1.mar = this.$n(nestedItem.in_march)
             item1.apr = this.$n(nestedItem.in_april)
             item1.may = this.$n(nestedItem.in_may)
             item1.jun = this.$n(nestedItem.in_june)
             item1.farmer = this.$n(nestedItem.farmer_number)
           } else {
             item1.serial = nestedItemCounter === 1 ? this.$n(serial) : ''
             item1.cropTypeName = nestedItemCounter === 1 ? item.crop_type_name : ''
             item1.cropName = nestedItem.crop_name
             item1.jul = this.$n(nestedItem.in_july)
             item1.aug = this.$n(nestedItem.in_august)
             item1.sep = this.$n(nestedItem.in_september)
             item1.oct = this.$n(nestedItem.in_october)
             item1.nov = this.$n(nestedItem.in_november)
             item1.dec = this.$n(nestedItem.in_december)
             item1.jan = this.$n(nestedItem.in_january)
             item1.feb = this.$n(nestedItem.in_february)
             item1.mar = this.$n(nestedItem.in_march)
             item1.apr = this.$n(nestedItem.in_april)
             item1.may = this.$n(nestedItem.in_may)
             item1.jun = this.$n(nestedItem.in_june)
             item1.farmer = this.$n(nestedItem.farmer_number)
           }
           nestedItemCounter++
           customizeData.push(item1)
         })
       })
       const item1 = {}
       item1.serial = `${this.$t('sitePreference.grossTotal')} (${this.$t('warehouse_report.quin')})`
       item1.cropTypeName = ''
       item1.cropName = ''
       item1.jul = this.$n(this.inJuly)
       item1.aug = this.$n(this.inAugust)
       item1.sep = this.$n(this.inSeptember)
       item1.oct = this.$n(this.inOctober)
       item1.nov = this.$n(this.inNovember)
       item1.dec = this.$n(this.inDecember)
       item1.jan = this.$n(this.inJanuary)
       item1.feb = this.$n(this.inFebruary)
       item1.mar = this.$n(this.inMarch)
       item1.apr = this.$n(this.inApril)
       item1.may = this.$n(this.inMay)
       item1.jun = this.$n(this.inJune)
       item1.farmer = this.$n(this.totalFarmer)
       customizeData.push(item1)
       return customizeData
    },
    headerInfo () {
      const firstRow = this.$t('globalTrans.division') + ' : ' + this.getDivisionName(this.search.division_id) + ' ' + this.$t('warehouse_config.region') + ' : ' + this.getRegionName(this.search.region_id) + ' ' + this.$t('globalTrans.district') + ' : ' + this.getDistrictName(this.search.district_id) + ' ' + this.$t('globalTrans.upazila') + ' : ' + this.getUpazilaName(this.search.upazilla_id)
      const secondRow = this.$t('globalTrans.union') + ' : ' + this.getUnionName(this.search.union_id) + ' ' + this.$t('warehouse_config.warehouse_type') + ' : ' + this.getWarehouseTypeName(this.search.warehouse_type_id) + ' ' + this.$t('warehouse_information.warehouse_name') + ' : ' + this.getWarehouseNameCustom(this.search.warehouse_id) + ' ' + this.$t('warehouse_config.fiscal_year') + ' : ' + this.getFiscalYearName(this.search.fiscal_year_id)
      return [this.$t('warehouse_report.month-wise-product-received-report'), '', firstRow, secondRow, '']
    },
    authUser () {
      return this.$store.state.Auth.authUser
    },
    loading () {
      return this.$store.state.commonObj.loading
    },
    listReload () {
      return this.$store.state.commonObj.listReload
    },
    loadingState () {
      if (this.listReload) {
        return true
      } else if (this.loading) {
        return true
      } else {
        return false
      }
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    },
    regionList: function () {
        return this.$store.state.warehouse.regionList.filter(item => item.status === 0)
    },
    fiscalYearList () {
        return this.$store.state.warehouse.fiscalYearList.filter(item => item.status === 0)
    },
    warehouseTypeList () {
        return this.$store.state.warehouse.warehouseTypeList.filter(item => item.status === 0)
    },
    currentLocale () {
      return this.$i18n.locale
    }
  },
  mounted () {
    core.index()
    if (this.authUser.warehouse_id) {
      const warehouseId = this.authUser.warehouse_id
      this.warehouseList = this.$store.state.warehouse.warehouseInfoList.filter(warehouseInfoItem => warehouseInfoItem.value === warehouseId)
      this.search.warehouse_id = warehouseId
    }
  },
  watch: {
    loadingState: function (newVal, oldVal) {
        if (newVal) {
        this.loadData()
        }
    },
    'search.region_id': function (newVal, oldVal) {
        this.regionDistrictLists = this.getRegionDistrictList(newVal)
    },
    'search.district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    },
    'search.upazila_id': function (newVal, oldVal) {
      this.unionList = this.getUnionList(newVal)
      this.warehouseList = this.getWarehouseName(newVal, 'UPZ')
    },
    'search.union_id': function (newVal, oldVal) {
      this.warehouseList = this.getWarehouseName(newVal, 'UP')
    },
    'search.warehouse_type_id': function (newVal, oldVal) {
      this.warehouseList = this.getWarehouseName(newVal, 'WT')
    }
  },
  methods: {
    resetId () {
      this.testId = 0
    },
    paginationDataLoad () {
      this.loadData()
    },
    default () {
      return {
        id: this.rows.length,
        name: '',
        name_bn: ''
      }
    },
    searchData () {
            this.loadData()
            this.showData = true
            this.showHeading = true
    },
    loadData () {
        this.$store.dispatch('mutateCommonProperties', { loading: true })
        RestApi.getData(warehouseServiceBaseUrl, monthWiseReportList, this.search).then(response => {
            if (response.success) {
                this.dataList(response)
            } else {
              this.monthWiseReportList = []
              this.monthWiseReport = []
              this.totalFarmer = ''
              this.inJuly = ''
              this.inAugust = ''
              this.inSeptember = ''
              this.inOctober = ''
              this.inNovember = ''
              this.inDecember = ''
              this.inJanuary = ''
              this.inFebruary = ''
              this.inMarch = ''
              this.inApril = ''
              this.inMay = ''
              this.inJune = ''
            }
            this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        }, error => {
            if (error) {
              //
            }
            this.monthWiseReportList = []
            this.monthWiseReport = []
            this.totalFarmer = ''
            this.inJuly = ''
            this.inAugust = ''
            this.inSeptember = ''
            this.inOctober = ''
            this.inNovember = ''
            this.inDecember = ''
            this.inJanuary = ''
            this.inFebruary = ''
            this.inMarch = ''
            this.inApril = ''
            this.inMay = ''
            this.inJune = ''
            this.$store.commit('mutateCommonProperties', { loading: false, listReload: false })
        })
    },
    dataList (data) {
    var tF = 0
    var jan = 0
    var feb = 0
    var mar = 0
    var apr = 0
    var may = 0
    var jun = 0
    var jul = 0
    var aug = 0
    var sep = 0
    var oct = 0
    var nov = 0
    var dec = 0
     this.monthWiseReportList = data.data
      this.monthWiseReportList.forEach((item) => {
       item.data.forEach((list) => {
           tF = tF + list.farmer_number
           jan = jan + list.in_january
           feb = feb + list.in_february
           mar = mar + list.in_march
           apr = apr + list.in_april
           may = may + list.in_may
           jun = jun + list.in_june
           jul = jul + list.in_july
           aug = aug + list.in_august
           sep = sep + list.in_september
           oct = oct + list.in_october
           nov = nov + list.in_november
           dec = dec + list.in_december
        })
    })

    this.totalFarmer = tF
    this.inJuly = jul
    this.inAugust = aug
    this.inSeptember = sep
    this.inOctober = oct
    this.inNovember = nov
    this.inDecember = dec
    this.inJanuary = jan
    this.inFebruary = feb
    this.inMarch = mar
    this.inApril = apr
    this.inMay = may
    this.inJune = jun

    const pdfArray = []
       this.monthWiseReportList.forEach(item => {
            item.data.forEach(listItem => {
                listItem.crop_type_name = item.crop_type_name
                listItem.crop_type_name_bn = item.crop_type_name_bn
                pdfArray.push(listItem)
            })
        })
    this.monthWiseReport = pdfArray
    },
    pdfExport () {
      const rowData = this.getPdfData()
      const labels = [
          { text: this.$t('globalTrans.sl_no'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_report.crop_type'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_report.crop_name'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('months.jul'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('months.aug'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('months.sep'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('months.oct'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('months.nov'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('months.dec'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('months.jan'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('months.feb'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('months.mar'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('months.apr'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('months.may'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('months.jun'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_report.total_farmer'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }
        ]
      rowData.unshift(labels)
      const reportTitle = this.$i18n.locale === 'en' ? 'Month Wise Product Received Report' : 'মাস অনুযায়ী শস্য/ কৃষিজাত পন্য সংরক্ষণ প্রতিবেদন'
      const columnWids = ['5%', '7%', '7%', '5%', '7%', '7%', '7%', '7%', '7%', '7%', '7%', '7%', '5%', '5%', '5%', '5%']
      const header1 = [
        { text: `${this.$t('globalTrans.division')} : ${this.getDivisionName(this.search.division_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: `${this.$t('warehouse_config.region')} : ${this.getRegionName(this.search.region_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: `${this.$t('globalTrans.district')} : ${this.getDistrictName(this.search.district_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: `${this.$t('globalTrans.upazila')} : ${this.getUpazilaName(this.search.upazilla_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }
      ]
      const header2 = [
        { text: `${this.$t('globalTrans.union')} : ${this.getUnionName(this.search.union_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: `${this.$t('warehouse_config.warehouse_type')} : ${this.getWarehouseTypeName(this.search.warehouse_type_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: `${this.$t('warehouse_information.warehouse_name')} : ${this.getWarehouseNameCustom(this.search.warehouse_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: `${this.$t('warehouse_config.fiscal_year')} : ${this.getFiscalYearName(this.search.fiscal_year_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }
      ]
      const extraData = {
        totalHeaderRows: 1,
        subHead: [header1, header2]
      }
      ExportPdf.exportPdf(warehouseServiceBaseUrl, '/master-warehouse-report-heading/detail', this.search.org_id, reportTitle, rowData, columnWids, extraData, 'month-wise-product-receive-report')
    },
    getPdfData () {
        const keys = [
          { key: 'serial_no' },
          { key: this.$i18n.locale === 'en' ? 'crop_type_name' : 'crop_type_name_bn' },
          { key: this.$i18n.locale === 'en' ? 'crop_name' : 'crop_name_bn' },
          { key: 'in_july' },
          { key: 'in_august' },
          { key: 'in_september' },
          { key: 'in_october' },
          { key: 'in_november' },
          { key: 'in_december' },
          { key: 'in_january' },
          { key: 'in_february' },
          { key: 'in_march' },
          { key: 'in_april' },
          { key: 'in_may' },
          { key: 'in_june' },
          { key: this.$i18n.locale === 'en' ? 'farmer_number' : 'farmer_number_bn' }
        ]
      var serial = 0
      var listData = this.monthWiseReport.map(item => {
        serial += 1
        const rowData = keys.map((keyItem, index) => {
            if (keyItem.key === 'serial_no') {
                return { text: this.$n(serial) }
            }
            if (keyItem.key === 'crop_type_name') {
                return { text: item.crop_type_name }
            }
            if (keyItem.key === 'crop_type_name_bn') {
                return { text: item.crop_type_name_bn }
            }
            if (keyItem.key === 'crop_name') {
                return { text: item.crop_name }
            }
            if (keyItem.key === 'crop_name_bn') {
                return { text: item.crop_name_bn }
            }
            if (keyItem.key === 'in_july') {
                return { text: this.$n(item.in_july) }
            }
            if (keyItem.key === 'in_august') {
                return { text: this.$n(item.in_august) }
            }
            if (keyItem.key === 'in_september') {
                return { text: this.$n(item.in_september) }
            }
            if (keyItem.key === 'in_october') {
                return { text: this.$n(item.in_october) }
            }
            if (keyItem.key === 'in_november') {
                return { text: this.$n(item.in_november) }
            }
            if (keyItem.key === 'in_december') {
                return { text: this.$n(item.in_december) }
            }
            if (keyItem.key === 'in_january') {
                return { text: this.$n(item.in_january) }
            }
            if (keyItem.key === 'in_february') {
                return { text: this.$n(item.in_february) }
            }
            if (keyItem.key === 'in_march') {
                return { text: this.$n(item.in_march) }
            }
            if (keyItem.key === 'in_april') {
                return { text: this.$n(item.in_april) }
            }
            if (keyItem.key === 'in_may') {
                return { text: this.$n(item.in_may) }
            }
            if (keyItem.key === 'in_june') {
                return { text: this.$n(item.in_june) }
            }
            if (keyItem.key === 'farmer_number_bn') {
                return { text: this.$n(item.farmer_number) }
            }
          return { text: item[keyItem.key] }
        })
        return rowData
      })
      const gross = [
            this.$i18n.locale === 'en' ? { text: 'GrossTotal (Quintal):' } : { text: 'সর্ব মোট (কুইন্টাল):', colSpan: 3 },
            { text: '' },
            { text: '' },
            { text: this.$n(this.inJuly) },
            { text: this.$n(this.inAugust) },
            { text: this.$n(this.inSeptember) },
            { text: this.$n(this.inOctober) },
            { text: this.$n(this.inNovember) },
            { text: this.$n(this.inDecember) },
            { text: this.$n(this.inJanuary) },
            { text: this.$n(this.inFebruary) },
            { text: this.$n(this.inMarch) },
            { text: this.$n(this.inApril) },
            { text: this.$n(this.inMay) },
            { text: this.$n(this.inJune) },
            { text: this.$n(this.totalFarmer) }
      ]
      listData.push(gross)
      return listData
    },
    async getRegionDistrictList (regionId = null) {
      this.districtLoading = true
      await RestApi.getData(warehouseServiceBaseUrl, '/master-warehouse-level/region-district-list').then(response => {
            if (response) {
                const dataObjectTemp = response.filter(document => document.region_id === regionId)
                const dataObject = dataObjectTemp.filter(item => item.status === 0)
                this.regionDistrictLists = dataObject.map(obj => {
                    if (this.$i18n.locale === 'bn') {
                        return { value: obj.district_id, text: this.$store.state.commonObj.districtList.find(document => document.value === obj.district_id).text_bn }
                    } else {
                        return { value: obj.district_id, text: this.$store.state.commonObj.districtList.find(document => document.value === obj.district_id).text }
                    }
                })
            }
        })
      this.districtLoading = false
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)

      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }

      return upazilaList
    },
    getUnionList (upazillaId = null) {
      const unionList = this.$store.state.commonObj.unionList.filter(item => item.status === 0)
      if (upazillaId) {
        return unionList.filter(union => union.upazilla_id === upazillaId)
      }
      return unionList
    },
    getWarehouseName (Id = null, type) {
      const warehouseList = this.$store.state.warehouse.warehouseInfoList.filter(item => item.status === 0)
        var list
      if (Id && type === 'UPZ') {
        list = warehouseList.filter(warehouse => warehouse.upazilla_id === Id)
         this.tempList = list
      }

      if (Id && type === 'UP') {
        list = warehouseList.filter(warehouse => warehouse.union_id === Id)
         this.tempList = list
      }

      if (Id && type === 'WT') {
        const filterData = this.tempList
        list = filterData.filter(warehouse => warehouse.warehouse_type_id === Id)
      }

      return list
    },
    getDivisionName (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.$store.state.commonObj.divisionList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getRegionName (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.$store.state.warehouse.regionList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getDistrictName (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.$store.state.commonObj.districtList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getUpazilaName (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.$store.state.commonObj.upazilaList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getUnionName (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.$store.state.commonObj.unionList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getWarehouseTypeName (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.$store.state.warehouse.warehouseTypeList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getWarehouseNameCustom (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.$store.state.warehouse.warehouseInfoList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getFiscalYearName (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.fiscalYearList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    }
  }
}
</script>
<style scoped>
 .container {
   display: flex;
   margin-bottom: 15px;
   justify-content: center;
 }
 .report-name {
   text-align: center;
 }
 .org-name {
  text-align: center;
 }
 .org-address {
   text-align: center;
 }
 .main-title {
   padding: 10px;
 }
 .project-name {
   text-align: center;
   font-weight: bold;
 }
</style>
